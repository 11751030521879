




























import Vue from 'vue';
import CreateMembershipRequestViewModel from '../../../../../services/viewModel/resource/CreateMembershipRequestViewModel';
import GuidLookupResourceViewModel from '../../../../../services/viewModel/resource/GuidLookupResourceViewModel';

export default Vue.extend({
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    gastronomyTypeItems: [] as GuidLookupResourceViewModel[],
  }),
  computed: {
    innerValue: {
      get(): CreateMembershipRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateMembershipRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
  async beforeMount() {
    const gastronomyTypes = await this.$service.api.attributes.getGastronomyTypes();
    this.gastronomyTypeItems = gastronomyTypes.items;
  },
});
